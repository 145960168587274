import { Transforms } from 'slate';

import { deserializeHtml } from '../html/deserializer';

// TODO: fix this with:
// https://github.com/udecode/slate-plugins/blob/next/packages/slate-plugins/src/deserializers/deserialize-html/withDeserializeHTML.ts
export function withHtml(editor) {
  const { insertData } = editor;

  editor.insertData = data => {
    const html = data.getData('text/html');

    console.log(html);

    if (html) {
      const fragment = deserializeHtml(html);
      console.log(fragment);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
}
