import React, { useCallback } from 'react';
import { Transforms } from 'slate';
import { Editable, Slate } from 'slate-react';
import isHotkey from 'is-hotkey';

import { LongformEditor } from '../core/editor';
import { renderElement, renderLeaf } from '../core/nodes';


export function Longform({ children, decorate, editor, keyHandlers, value, onChange, readOnly }) {
  const keyHandler = useCallback((event) => {

    if (isHotkey('tab', event) && LongformEditor.isListActive(editor)) {
      event.preventDefault();
      LongformEditor.indentList(editor);
      return;
    } else if (isHotkey('shift+tab', event) && LongformEditor.isListActive(editor)) {
      event.preventDefault();
      LongformEditor.dedentList(editor);
      return;
    }

    // Don't add a new block when hitting enter inside a "soft break" block (e.g. code).
    if (event.key === 'Enter' && (event.shiftKey || LongformEditor.insideSoftBreak(editor))) {
      event.preventDefault();
      editor.insertText('\n');
      return;
    } else if (event.key === "Enter" && !LongformEditor.isListActive(editor)) {
      // Reset the markup when hitting enter outside of lists.
      event.preventDefault();
      const newLine = {
          type: "paragraph",
          children: [
              {
                  text: "",
                  marks: []
              }
          ]
      };
      Transforms.insertNodes(editor, newLine);
    }

    if (event.ctrlKey) {
      if (event.key === '`') {
        event.preventDefault();
        LongformEditor.toggleBlock(editor, 'code')
      }
    }

    keyHandlers.forEach((h) => h(event));
    if (!event.metaKey) {
      return;
    }
    switch (event.key) {
      case 'b': {
        event.preventDefault();
        LongformEditor.toggleMark(editor, 'bold');
        break;
      }
      case 'i': {
        event.preventDefault();
        LongformEditor.toggleMark(editor, 'italic');
        break;
      }
      case 'u': {
        event.preventDefault();
        LongformEditor.toggleMark(editor, 'underline');
        break;
      }
      case '1': {
        event.preventDefault();
        LongformEditor.toggleBlock(editor, 'heading-one');
        break;
      }
      case '2': {
        event.preventDefault();
        LongformEditor.toggleBlock(editor, 'heading-two');
        break;
      }
      case '3': {
        event.preventDefault();
        LongformEditor.toggleBlock(editor, 'heading-three');
        break;
      }
      case '4': {
        event.preventDefault();
        LongformEditor.toggleBlock(editor, 'heading-four');
        break;
      }
    }
  }, []);

  return <Slate editor={editor} onChange={onChange} value={value}>
    {children}
    <Editable
      decorate={decorate}
      readOnly={readOnly}
      onKeyDown={keyHandler}
      renderElement={renderElement}
      renderLeaf={renderLeaf} />
  </Slate>;
}
