const BLOCK_TAGS = [
  'P', 'DIV', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'BLOCKQUOTE', 'UL', 'OL', 'LI', 'PRE', 'BODY'
];

const dom = {
  isBlockElement(node) {
    return BLOCK_TAGS.includes(node.nodeName);
  },

  fromString(content) {
    return new DOMParser().parseFromString(content, 'text/html').body;
  },

  isAllWs(node) {
    // Use ECMA-262 Edition 3 String and RegExp features
    return !(/[^\t\n\r ]/.test(node.textContent));
  },

  nodeBefore( node ) {
    let sib = node;
    // check the current node for siblings
    while ((sib = sib.previousSibling)) {
      if (!dom.isIgnorable(sib)) return sib;
    }

    if (node.parentNode !== null && !dom.isBlockElement(node.parentNode)) {
      return dom.nodeBefore(node.parentNode);
    }

    return null;
  },

  nodeAfter( node ) {
    let sib = node;
    while ((sib = sib.nextSibling)) {
      if (!dom.isIgnorable(sib)) return sib;
    }

    if (node.parentNode !== null && !dom.isBlockElement(node.parentNode)) {
      return dom.nodeAfter(node.parentNode);
    }

    return null;
  },

  isIgnorable(node) {
    return ( node.nodeType == 8) || // A comment node
          ( (node.nodeType == 3) && dom.isAllWs(node) ); // a text node, all ws
  }
}

export default dom;
