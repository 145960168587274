import { ReactEditor } from 'slate-react';
import { debounce } from 'debounce';

export function scrollToSelection() {
  const domSelection = window.getSelection();
  const domRange = domSelection.getRangeAt(0);
  const rect = domRange.getBoundingClientRect();

  window.scrollTo({
    left: 0,
    top: (window.scrollY + rect.top) - parseInt(window.innerHeight / 2, 10),
    behavior: 'smooth'
  });
}

/**
 * Keep the current caret in the middle of your viewport. (For focused-mode).
 *
 * TODO: Make it so that you can toggle this on and off without having to install/uninstall the
 *       plugin (for a "focus mode" style thing).
 */
export function withScrollspy(editor) {
  const { apply } = editor;
  const scroll = debounce(() => scrollToSelection(), 200);
  editor.apply = (op) => {
    apply(op);
    if (op.type === 'set_selection' || op.type === 'insert_text') {
      if (ReactEditor.isFocused(editor)) {
        scroll();
      }
    }
  }
  return editor;
}
