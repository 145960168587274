import React from 'react';

export function Circular({ size, url }) {
  return <img class={`inline-block h-${size} w-${size} rounded-full`} src={url} />
}

export function Rounded({ size, url }) {
  return <img class={`inline-block h-${size} w-${size} rounded-md`} src={url} />
}

export function CircularStatus({ color = 'gray-300', size, url }) {
  return <span class='inline-block relative'>
    <Circular size={size} url={url} />
    <span class={`absolute top-0 right-0 block h-${size / 4} w-${size / 4} rounded-full ring-2 ring-white bg-${color}`} />
  </span>
}

