import { Text } from 'slate';

export function serializeHtml(node) {
  return renderElement(node);
}

function renderLeaf(leaf, respectNewLines) {
  let text = leaf.text;

  if (respectNewLines) {
    text = text.replace(/\n/g, '<br/>');
  }

  if (leaf.bold) {
    text = `<strong>${text}</strong>`;
  }
  if (leaf.code) {
    text = `<code>${text}</code>`;
  }
  if (leaf.italic) {
    text = `<em>${text}</em>`;
  }
  if (leaf.underline) {
    text = `<u>${text}</u>`;
  }
  if (leaf.highlight) {
    text = `<span className='highlight'>${text}</span>`;
  }
  if (leaf.mark) {
    text = `<mark>${text}</mark>`;
  }
  if (leaf.strikethrough) {
    text = `<del>${text}</del>`;
  }
  if (leaf.small) {
    text = `<small>${text}</small>`;
  }
  return `${text}`;
}

function renderElement(node, respectNewLines = false) {
  if (Text.isText(node)) {
    return renderLeaf(node, respectNewLines);
  }

  const children = node.children.map(c => renderElement(c, node.type !== 'code')).join('');

  switch (node.type) {
    case 'code':
      return `<pre><code>${children}</code></pre>`;
    case 'image':
      return `<img src="${node.url}" alt="${node.title || ''}" />`;
    case 'block-quote':
      return `<blockquote>${children}</blockquote>`;
    case 'numbered-list':
        return `<ol>${children}</ol>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'heading-one':
      return `<h1>${children}</h1>`;
    case 'heading-two':
      return `<h2>${children}</h2>`;
    case 'heading-three':
      return `<h3>${children}</h3>`;
    case 'heading-four':
      return `<h4>${children}</h4>`;
    case 'heading-five':
      return `<h5>${children}</h5>`;
    case 'heading-six':
      return `<h6>${children}</h6>`;
    case 'list-item':
        return `<li>${children}</li>`;
    case 'link':
        return `<a href="${node.url}">${children}</a>`;
    case 'hr':
        return `<hr />`;
    case 'paragraph':
      return `<p>${children}</p>`;
    default:
      return children;
  }
};