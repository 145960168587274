import isUrl from 'is-url';
import { Element, Transforms } from 'slate';
import { LongformEditor } from '../core/editor';

export function withLinks(editor) {
  const { insertData, insertText, isInline, normalizeNode } = editor;

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = text => {
    if (text && isUrl(text)) {
      LongformEditor.wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      LongformEditor.wrapLink(editor, text);
    } else {
      insertData(data)
    }
  };

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    // Remove any links that don't have content - i.e. have exactly one child with no text.
    if (Element.isElement(node) && node.type === 'link') {
      if (node.children.length === 1 && !Element.isElement(node.children[0]) && node.children[0].text === '') {
        Transforms.removeNodes(editor, { at: path });
        return
      }
    }

    normalizeNode(entry);
  }

  return editor;
}
