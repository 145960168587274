import { Transforms, Node, Path } from "slate";
import { LongformEditor } from "../core/editor";

/**
 * Make sure that lists only contain li entries as top-level children. Whenever a mistake is made,
 * just wrap the bad element in a list.
 */
export function withLists(editor) {
  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (node.type === 'bulleted-list' || node.type === 'numbered-list') {
       // Make sure ul+li and ol+li is respected.
       for (const [child, childPath] of Node.children(editor, path)) {
         if (child.type !== 'list-item') {
           Transforms.wrapNodes(editor, { type: 'list-item', children: []}, { at: childPath });
           return;
         }
       }

       // Merge adjacent lists..
       // Peak at next node, if they are both the same list type...
       // "Merge them" (i guess just moving the children of the next list to this one)
       const nextEntry = LongformEditor.next(editor, {at: path});
       console.log(nextEntry);
       if (nextEntry) {
         const [nextNode, nextPath] = nextEntry;
         if (node.type === nextNode.type) {
          console.log('I can do something...');
         }
       }
    }

    normalizeNode(entry);
  };

  return editor;
}
