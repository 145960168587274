import React from 'react';
import { useSelected, useFocused }  from 'slate-react';

export const Leaf = ({attributes, children, leaf}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.highlight) {
    children = <span className='highlight'>{children}</span>;
  }
  if (leaf.mark) {
    children = <mark>{children}</mark>;
  }
  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }
  if (leaf.small) {
    children = <small>{children}</small>;
  }
  return <span {...attributes}>{children}</span>;
};

function ImageElement({ attributes, children, element }) {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <img
          alt={element.title}
          className={`${selected && focused ? 'image-selected' : ''}`}
          src={element.url} />
      </div>
      {children}
    </div>
  );
}

export const Element = (props) => {
  const {attributes, children, element} = props;
  switch (element.type) {
    case 'code':
      return <pre {...attributes}>{children}</pre>
    case 'image':
      return <ImageElement {...props} />;
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>
    case 'list-item':
        return <li {...attributes}>{children}</li>
    case 'link':
        return <a {...attributes} href={element.url}>{children}</a>
    case 'hr':
        return <hr {...attributes} />
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const renderLeaf = (props) => <Leaf {...props} />;
export const renderElement = (props) => <Element {...props} />;
