import React from 'react';

import {
  HiOutlineInbox,
  HiOutlineHome,
  HiOutlineAnnotation,
  HiOutlineBookOpen,
} from 'react-icons/hi';

import { Circular, Rounded, CircularStatus, Dropdown } from '../tailwind';

function PrimaryButton({ icon, text }) {
  return <button type="button" class="inline-flex items-center px-12 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
  Write
</button>
}

export default function Hello() {
  const url = 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';
  return <div className="h-screen flex overflow-hidden bg-white">
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex relative flex-col w-64">
        <Nav />
      </div>
    </div>
    <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
      <div className="flex-1 relative z-0 flex overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-first" tabindex="0">
          <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
            <div className="h-full border-4 border-gray-200 border-dashed rounded-lg"></div>
          </div>
        </main>
        <aside className="hidden relative xl:order-last xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
          <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
            <div className='flex flex-row items-center justify-around'>
              <Circular size={12} url={url} />
              <Rounded size={12} url={url} />
              <CircularStatus color='green-400' size={12} url={url} />
            </div>
              <hr class='m-2' />
              <Dropdown text='Hello, World!' options={['One', 'Two', 'Three']} />
          </div>
        </aside>
      </div>
    </div>
  </div>;
}

function NavItem({ active, icon, text, url = '#' }) {
  const colors = active ? 'bg-gray-100 text-gray-900' : 'text-gray-800 hover:bg-yellow-100 hover:text-gray-900';
  return <a href={url} class={`${colors} group flex items-center px-2 py-2 text-base font-medium rounded-md`}>
    {icon} {text}
  </a>;
}

function Nav() {
  return <nav aria-label="Sidebar" class="mt-5 space-y-8">
  <div class="px-2 space-y-1">
    <NavItem active icon={<HiOutlineHome class='text-gray-700 mr-4 h-6 w-6' />} text='Dashboard' />
    <NavItem icon={<HiOutlineInbox class='text-gray-600 mr-4 h-6 w-6' />} text='Inbox' />
    <NavItem icon={<HiOutlineAnnotation class='text-gray-600 mr-4 h-6 w-6' />} text='Notes' />
    <NavItem icon={<HiOutlineBookOpen class='text-gray-600 mr-4 h-6 w-6' />} text='OCR' />
  </div>
  <div class="px-2 space-y-1">
  <h3 class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
          Collections
        </h3>
        <div class="space-y-1" role="group" aria-labelledby="projects-headline">
          <a href="#" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
            <span class="truncate">
              Tech Lead Lessons
            </span>
          </a>

          <a href="#" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
            <span class="truncate">
              Principles
            </span>
          </a>

          <a href="#" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
            <span class="truncate">
              Novel
            </span>
          </a>

          <a href="#" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
            <span class="truncate">
              Meditations
            </span>
          </a>
        </div>
  </div>
</nav>
}