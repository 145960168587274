import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import WebFont from 'webfontloader';

import Tailwind from './components/Tailwind';

import { ProvideStore } from './store';

import { PrivateRoute, ProvideAuth } from './auth';

import Dashboard from './site/pages/dashboard';
import Login from './site/pages/login';
import Post from './site/pages/post';
import Weight from './site/pages/weight';
import Write from './site/pages/write';

import './App.css';

WebFont.load({
  google: {
    families: [
      'Inter:100,200,300,400,500,600,700,800,900',
      'Crimson Text:400,600,700'
    ]
  }
});

function App() {
  return (
    <ProvideAuth>
      <ProvideStore>
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>

            <PrivateRoute path="/tailwind">
              <Tailwind />
            </PrivateRoute>
            <PrivateRoute path="/view/:id">
              <Post />
            </PrivateRoute>
            <PrivateRoute path="/post/:id">
              <Write />
            </PrivateRoute>
            <PrivateRoute path="/write">
              <Write />
            </PrivateRoute>
            <PrivateRoute path="/weight">
              <Weight />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </ProvideStore>
    </ProvideAuth>
  );
}

export default App;
