import { Transforms } from 'slate';

export function withBlogLayout(editor) {
  const { normalizeNode } = editor;

  // Force the first node to always be a heading-one.
  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length < 1) {
        const title = { 'type': 'heading-one', children: [{ text: '' }] };
        Transforms.insertNodes(editor, title, { at: path.concat(0) });
      } else if (editor.children[0].type !== 'heading-one') {
        Transforms.setNodes(editor, {type: 'heading-one'}, {at: [0]});
      }
    }
    return normalizeNode([node, path]);
  };

  return editor;
}
