import { Node, Text } from 'slate';
import TurndownService from 'turndown';

import { serializeHtml } from '../html/serializer';

function withNewLine(content) {
  return `${content}\n`;
}

function isInline(node) {
  return node.type === 'link';
}

export function serializeMarkdown(node) {
  const turndownService = new TurndownService({
    headingStyle: 'atx',
    bulletListMarker: '-',
    emDelimiter: '*',
    codeBlockStyle: 'fenced'
  });
  turndownService.keep(['del', 'mark', 'small']);
  return turndownService.turndown(serializeHtml(node));
}

export function serializeMarkdown2(node) {
  return _serialize(node, {}, 0);
}

function _serialize(node, parentNode, indent=0) {
  if (Text.isText(node)) {
    return renderLeaf(node);
  }

  const children = node.children.map(n => _serialize(n, node, 0)).join('');
  return renderBlock(node, children, parentNode);
}

function renderLeaf(node) {
  let text = node.text;

  if (node.bold) {
    text = `**${text}**`;
  }
  if (node.code) {
    text = "`" + text + "`";
  }
  if (node.italic) {
    text = `*${text}*`;
  }
  if (node.underline) {
    text = `<u>${text}</u>`;
  }
  if (node.mark) {
    text = `<mark>${text}</mark>`;
  }
  if (node.small) {
    text = `<small>${text}</small>`;
  }

  text = text.replace(/\n/g, "  \n");

  return text;
}

function renderBlock(node, children, parentNode) {
  switch (node.type) {
    case 'code':
      return "```\n" + children + "\n```\n\n";
    case 'image':
      return `![${node.title || ''}](${node.url})`;
    case 'block-quote':
      const lines = children.trim().split('\n').map(l => `> ${l}`).join('\n');
      return `${lines}\n\n`;
    case 'numbered-list':
      return `${children}\n`;
    case 'bulleted-list':
      return `${children}\n`;
    case 'link':
      return `[${children}](${node.url})`;
    case 'heading-one':
      return `# ${children}\n\n`;
    case 'heading-two':
      return `## ${children}\n\n`;
    case 'heading-three':
      return `### ${children}\n\n`;
    case 'heading-four':
      return `#### ${children}\n\n`;
    case 'heading-five':
      return `##### ${children}\n\n`;
    case 'heading-six':
      return `###### ${children}\n\n`;
    case 'paragraph':
      return parentNode.type !== 'block-quote' ? `${children}\n\n` : `${children}\n`;
    case 'list-item':
      return parentNode.type === 'bulleted-list' ? `- ${children}\n` : `1. ${children}\n`;
    case 'hr':
      return `---\n\n`;
    default:
      return children;
  }
}

