import React, { useContext, createContext, useState } from 'react';

import { Redirect, Route } from 'react-router-dom';

const auth = {
  check(cb) {
    fetch('/api/auth', { credentials: 'same-origin' }).then(res => {
      if (res.status === 200) {
        res.text().then(username => {
          cb(username)
        })
      } else {
        cb();
      }
    });
    return false;
  },
  signin(username, password, cb) {
    fetch('/api/login', {
      credentials: 'same-origin',
      headers: {"Content-Type": "application/json"},
      method: 'POST',
      body: JSON.stringify({username: username, password: password})
    }).then(res => {
      if (res.status === 200) {
        cb(username);
      }
    });
  },
  signout(cb) {
    fetch('/api/logout', {
      credentials: 'same-origin',
      headers: {"Content-Type": "application/json"},
      method: 'POST'
    }).then(res => {
      auth.isAuthenticated = false;
      cb();
    });
  }
};

const authContext = createContext();

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [checked, setChecked] = useState(false);
  const [user, setUser] = useState();
  const signin = (username, password, cb = () => {}) => {
    return auth.signin(username, password, () => {
      setUser(username);
      cb();
    });
  };

  const signout = cb => {
    return auth.signout(() => {
      setUser(null);
      if (cb) {
        cb();
      }
    });
  };

  const check = () => {
    if (checked) {
      return true;
    }

    auth.check((username) => {
      if (username) {
        setUser(username);
      }
      setChecked(true);
    });
  };

  return {
    user,
    check,
    signin,
    signout
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route {...rest} render={({ location }) => {
      return auth.user && auth.user.length > 0
        ? children
        : auth.check()
        ? <Redirect to={{pathname: '/login', state: { from: location }}} />
        : <div>Checking auth...</div>;
    }} />
  );
}
