import marked from 'marked';

import { deserializeHtml } from '../html/deserializer';

export function deserializeMarkdown2(content) {
  const lexer = marked.lexer(content);
  // console.log(marked(content));
  // console.log(JSON.stringify(lexer, null, 2));
  return lexer.map(deserializeMarkedNode);
}

export function deserializeMarkdown(content) {
  const html = marked(content);
  return deserializeHtml(html);
}

function getHeadingFromDepth(depth) {
  switch (depth) {
    case 1:
      return 'heading-one';
    case 2:
      return 'heading-two';
    case 3:
      return 'heading-three';
    case 4:
      return 'heading-four';
    case 5:
      return 'heading-five';
  }
  return 'heading-six';
}

function normalizeTokens(tokens) {
  // just remove them for now...
  return tokens.filter(t => t.type !== 'html' && !(t.type === 'space' && t.raw === "\n\n"));
}

const MARKED_INLINES = ['strong', 'em', 'codespan'];

function deserializeInlineNode(node, props = {}) {
  switch (node.type) {
    case 'strong':
      props.bold = true;
      node.tokens.forEach((t) => deserializeInlineNode(t, props));
      break;
    case 'em':
      props.italic = true;
      node.tokens.forEach((t) => deserializeInlineNode(t, props));
      break;
    case 'codespan':
      props.code = true;
      props.text = node.text;
      break;
    case 'text':
      props.text = node.text;
      break;
  }

  return props;
}

function deserializeListItem(item) {
  /*
    For some reason can have a different structure of:

    {
      "type": "list_item",
      "tokens": [{
        "type": "text",
        "text": "One",
        "tokens": [{
          "type": "text",
          "text": "One"
        }]
      }]
    }

    With a text node that has tokens.

    Kind of like the ul>li>p+ol+ul constraint for lists we try to enforce. This top-level
    text node is basically a paragraph.

    TODO: I should be able to generalize this handling as a text node that has tokens is just a
    p node. No?
   */
  item.tokens.forEach((t) => {
    if (t.type === 'text') {
      t.type = 'paragraph'
    }
  });
  return {
    type: 'list-item',
    children: item.tokens.map(deserializeMarkedNode)
  };
}

export function deserializeMarkedNode(node) {
  const { tokens = [] } = node;
  if (MARKED_INLINES.includes(node.type)) {
    return deserializeInlineNode(node);
  }

  const children = tokens.length > 0
    ? normalizeTokens(node.tokens).map(deserializeMarkedNode) : [];

  switch (node.type) {
    case 'text':
      return {
        text: node.raw
      };
    case 'space':
      return {
        type: 'paragraph',
        children: [{
          text: ''
        }]
      };
    case 'heading':
      return {
        type: getHeadingFromDepth(node.depth),
        children
      };
    case 'blockquote':
      return {
        type: 'block-quote',
        children
      };
    case 'link':
      return {
        type: 'link',
        url: node.href,
        children
      }
    case 'image':
      return {
        type: 'image',
        url: node.href,
        title: node.text,
        children: [{
          text: ''
        }]
      }
    case 'code':
      return {
        type: 'code',
        lang: node.lang,
        children: [{
          text: node.text
        }]
      }
    case 'list':
      return {
        type: node.ordered ? 'numbered-list' : 'bulleted-list',
        children: node.items.map(deserializeListItem)
      };
    case 'paragraph':
      return {
        type: 'paragraph',
        children
      };
  }
}
