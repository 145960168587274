import React, { useEffect, useState } from 'react';

export default function Weight(props) {
  const [notes, setNotes] = useState();

  useEffect(() => {
    fetch('/api/notes/weight', {
        credentials: 'same-origin',
        headers: {"Content-Type": "application/json"}
      }).then(res => {
        res.json().then((data) => {
          const grouped = {};
          data.forEach((note) => {
            const key = new Date(note.created).toLocaleDateString('en-gb', {year:'numeric', month: 'numeric', day: 'numeric'});
            if (!grouped.hasOwnProperty(key)) {
              grouped[key] = [];
            }
            grouped[key].push(note);
          });
          const sorted = Object.keys(grouped).sort((a, b) => a > b ? 1 : b > a ? 1 : 0);          
          setNotes(sorted.map(k => grouped[k]));
        });
      });           
  }, []);

  if (notes && notes.length > 0) {
    return <div>
    <div class="max-w-7xl mx-auto py-2 px-0 sm:px-4 sm:px-6 lg:px-8">
      <div class='mt-12 sm:max-w-lg mx-auto grid gap-5 sm:grid-cols-2 lg:grid-cols-3 lg:max-w-none'>
        {notes.map((n) => <WeightItem notes={n} />)}
      </div>
    </div></div>;
  }
  return <div>Loading..</div>;
}

function WeightItem({ notes }) {
  const [idx, setIdx] = useState(0);
  const note = notes[idx];
  return <div class="flex flex-col sm:rounded-lg shadow-sm sm:shadow-lg overflow-hidden" onClick={(e) => setIdx((idx < notes.length - 1) ? idx + 1 : 0)}>
  <div class="flex-shrink-0 relative">
    <img class="h-80 sm:h-64 w-full object-cover" src={`/image/${note.image_id}`} alt="" />
    {notes.length > 1 && <span class="absolute top-6 right-6 block h-2 w-2 transform -translate-y-1/2 translate-x-1/2 rounded-full ring-2 ring-white bg-indigo-50"></span>}
  </div>
  <div class="flex-1 bg-white p-6 flex flex-col justify-between">
    <div class="flex-1">
      <p class="text-sm font-medium text-indigo-600">
        <a href="#" class="hover:underline">
          {new Date(note.created).toLocaleDateString('en-gb', { year: 'numeric', month: 'long', day: 'numeric'})}
        </a>
      </p>
      <a href="#" class="block mt-2">
        <p class="mt-3 text-base text-gray-500">
          {note.content}
        </p>
      </a>
    </div>
  </div>
</div>;
}