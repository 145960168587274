import isUrl from 'is-url';
import imageExtensions from 'image-extensions'
import { LongformEditor } from '../core/editor';

const isImageUrl = url => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext)
}

export function withImages(editor) {
  const { insertData, isVoid } = editor;

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            LongformEditor.insertImage(editor, url)
          });
          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      LongformEditor.insertImage(editor, text)
    } else {
      insertData(data)
    }
  };

  return editor;
}
