
import { createContext, useContext } from 'react';

const PREFIX = '__longform_post_';

const RemoteManager = {
  getAll() {
    return fetch('/api/posts', {
      credentials: 'include',
      headers: {"Content-Type": "application/json"},
      method: 'GET'
    }).then(res => {
      return res.json();
    });
  },

  save(title, content, id) {
    const url = id ? `/api/write/${id}` : `/api/write`;
    const options = {
      credentials: 'same-origin',
      headers: {"Content-Type": "application/json"},
      method: 'POST',
      body: JSON.stringify({
        content: this.state.content,
        title: this.state.title
      })
    };
    fetch(url, options).then((res) => {
      if (res.status === 201) {
        return res.json();
      }
      return res;
    });
  }
};

const PostManager = {
  getAll: function() {
    const keys = Object.keys(localStorage).filter(k => k.startsWith(PREFIX));
    return keys.map((k) => {
      const post = JSON.parse(localStorage.getItem(k));
      return {
        id: k.substr(PREFIX.length),
        title: post[0].children[0].text || 'Untitled'
      };
    });
  },

  create: function() {
    const keys = Object.keys(localStorage).filter(k => k.startsWith(PREFIX));
    localStorage.setItem(
      `${PREFIX}${keys.length}`,
      JSON.stringify([{type: 'heading-one', children:[{text:''}]}]));
    return keys.length;
  },

  save: function(id, content) {
    localStorage.setItem(`${PREFIX}${id}`, JSON.stringify(content));
  },

  get: function(id) {
    return JSON.parse(localStorage.getItem(`${PREFIX}${id}`));
  }
};

const store = createContext();

export function ProvideStore({ children }) {
  return <store.Provider value={PostManager}>
    {children}
  </store.Provider>;
}

export function useStore() {
  return useContext(store);
}
