import React, { useEffect, useRef, useState } from 'react';

import { HiChevronDown } from 'react-icons/hi';

function useComponentVisible(initialVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialVisible);
  const ref = useRef(null);

  const handleHideDropdown = event => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown);
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown);
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

export function Dropdown({ text, options }) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return <div class='relative inline-block text-left'>
    <div ref={ref}>
      <button type='button' class='inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' onClick={(e) => setIsComponentVisible((state) => !state)}>
        {text}
        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
      </svg>
      </button>
    </div>
    {isComponentVisible && <div class='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
      <div class='py-1'>
        {options.map((o) => <a href='#' class='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'>{o}</a>)}
      </div>
    </div>}
  </div>;
}
