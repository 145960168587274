import { Editor, Element as SlateElement, Path, Range, Transforms } from 'slate';
import { Transform } from 'stream';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const INLINE_TYPES = ['code'];

export const LongformEditor = {
  ...Editor,

  isBlockActive: (editor, format) => {
    const [match] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })

    return !!match
  },

  isListActive: (editor) => {
    return (LongformEditor.isBlockActive(editor, 'numbered-list') ||
      LongformEditor.isBlockActive(editor, 'bulleted-list'));
  },

  dedentList: (editor) => {
    if (LongformEditor.isListActive(editor)) {
      // We want to look at every 'list-item' leaf node that has been selected
      // and shift it left.
      if (editor.selection) {
        // This returns every list-item leaf that is part of the current selection.
        const matches = LongformEditor.nodes(editor,
          { match: n => n.type === 'list-item', mode: 'lowest'});
        const pathRefs = Array.from(matches, ([, p]) => LongformEditor.pathRef(editor, p))
        for (const pathRef of pathRefs) {
          const path = pathRef.unref()

          // List items at the root of the doc need to be converted to paragraphs.
          if (path.length === 2) {
            // TODO: Technically you can do this, but emulating Google Docs here...
            // Transforms.unwrapNodes(editor, {at: path, split: true, match: n => n.type === 'bulleted-list'});
            // Transforms.setNodes(editor, {at: path, type: 'paragraph'});
            return;
          }

          // List items in nested lists need to be moved to parent lists.
          // So imagine:
          //
          //  ul
          //    li
          //      ul
          //        li
          //        li <--- we want to shift this left
          //        li
          //
          // We want to end up with:
          //
          //  ul
          //    li
          //      ul
          //        li
          //    li <--- i got shifted left
          //    li
          //      ul
          //        li
          //
          // That is done via the following sequence:
          //
          //  ul
          //    li
          //      ul
          //        li
          //        li
          //    li <--- (1) move me out to the next sibling of the parent.
          //
          //  ul
          //    li
          //      ul
          //        li
          //    li <--- (1) move me out to the next sibling of the parent.
          //    li
          //      ul
          //        li <--- (2) move me out to my own sublist after the guy above. thus preserving
          //                    order

          // I belong to a list I a moving out of.
          const [parent, parentPath] = Editor.node(editor, Path.parent(path));
          // This is the li that wraps the parent that I'm being moved to.
          const [grandparent, grandparentPath] = Editor.node(editor, Path.parent(Path.parent(path)))

          const index = path[path.length - 1];
          const parentIndex = parentPath[parentPath.length - 1];
          const { length } = parent.children


          // This was the only child
          if (length === 1) {
            const toPath = Path.next(grandparentPath)
            Transforms.moveNodes(editor, {at: path, to: toPath });
            // If this was the only child in the parent list, we should remove the grandparent.
            Transforms.removeNodes(editor, {at: grandparentPath });
          } else if (index === 0) {
            // I was the first in the list
            Transforms.moveNodes(editor, { at: path, to: grandparentPath });
          } else if (index === length - 1) {
            // I was the last in the list
            const toPath = Path.next(grandparentPath);
            Transforms.moveNodes(editor, { at: path, to: toPath });
          } else {
            // Middle of the list...
            const splitPath = Path.next(path);
            const toPath = Path.next(grandparentPath);
            // Split and move the seleced element out..
            Transforms.splitNodes(editor, {at: splitPath });
            Transforms.moveNodes(editor, { at: path, to: toPath });
            // Then move the list that was created (using the parent next path) after it.
            const anotherPath = Path.next(toPath);
            Transforms.moveNodes(editor, { at: Path.next(parentPath), to: anotherPath });
            // Transforms.wrapNodes(editor, { type: 'list-item', children: []}, { at: anotherPath });
            return;
          }
        }
        return;
      }
    }
  },

  indentList: (editor) => {
    // First, let's figure out if we're at the start of the selection..
    // Double check, I guess.
    if (LongformEditor.isListActive(editor)) {
      if (editor.selection) {
        Transforms.wrapNodes(editor, {type: 'bulleted-list', children: []});
      }
    }
  },

  toggleBlock: (editor, format) => {
    const isActive = LongformEditor.isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    const canBeInlined = INLINE_TYPES.includes(format);
    if (canBeInlined && editor.selection && !Range.isCollapsed(editor.selection)) {
      LongformEditor.toggleMark(editor, format);
      return;
    }

    Transforms.unwrapNodes(editor, {
      match: n =>
        LIST_TYPES.includes(
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
        ),
      split: true,
    })
    const newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }

    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  },

  isMarkActive: (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  },

  toggleMark: (editor, format) => {
    const isActive = LongformEditor.isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  },

  doubleNewLine: (editor) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range)

      if (beforeText.endsWith('\n')) {
        return true;
      }
    }
    return false;
  },

  needsBreakout: (editor) => {
    return LongformEditor.isBlockActive(editor, 'code');
  },

  insideSoftBreak: (editor) => {
    const node = Editor.above(editor, {match: n => Editor.isBlock(editor, n)}) || [editor, []];
    return node[0].type === 'code' && !LongformEditor.doubleNewLine(editor);
  },

  unwrapLink: (editor) => {
    Transforms.unwrapNodes(editor, {
      match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link'
    });
  },

  insertImage: (editor, url) => {
    const text = { text: '' };
    const image = { type: 'image', url, children: [text] };
    Transforms.insertNodes(editor, image);
  },

  wrapLink: (editor, url) => {
    if (LongformEditor.isBlockActive('link')) {
      LongformEditor.unwrapLink(editor)
      return
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: 'link',
      url,
      children: isCollapsed ? [{ text: url }] : []
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, {split: true});
      Transforms.collapse(editor, { end: 'end' });
    }
  },

  insertLink: (editor, url) => {
    if (editor.selection) {
      LongformEditor.wrapLink(editor, url);
    }
  }
};
