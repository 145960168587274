import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStore } from '../../store';

import { deserializeMarkdown, serializeHtml } from '../../editor';

// export default function Post() {
//   const [font, setFont] = useState('EB Garamond');
//   const store = useStore();
//   const opts = useParams();
//   const post = store.get(opts.id);

//   useEffect(() => {
//     WebFont.load({
//       google: {
//         families: [
//           `${font}:100,200,300,400,500,600,700,800,900`
//         ]
//       }
//     });
//   }, [font]);

//   const markdown = serializeMarkdown({children: post});
//   return <div className='editor-panel flex justify-center'>
//     <div className='p-10 inline-block'>
//       <input type="text" onChange={(e) => setFont(e.target.value)} value={font} />
//       <div className='prose lg:prose-xl xl:prose-xl 2xl:prose-2xl' style={{fontFamily: font}}>
//         <div dangerouslySetInnerHTML={{__html: marked(markdown)}} />
//       </div>
//     </div>
//   </div>;
// }

export default function Post() {
  const store = useStore();
  const { id } = useParams();
  const [post, setPost] = useState();

  useEffect(() => {
    fetch(`/api/post/${id}`, {credentials: 'same-origin', method: 'GET'})
      .then((res) => {
        if (res.status === 200) {
          res.json().then((post) => {
            const doc = [
              {type: 'heading-one', children: [{text: post.title}]},
              ...deserializeMarkdown(post.content)
            ];
            setPost(doc);
          });
        }
      });
  }, [id, store]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return <div className='editor-panel flex justify-center'>
    <div className='grid'>
      <div style={{maxWidth: '800px'}}>
        <div dangerouslySetInnerHTML={{__html: serializeHtml({children: post})}} />
      </div>
    </div>
  </div>;
}
