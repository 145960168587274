import React from 'react';
import { useSlate } from 'slate-react';

import {
  MdFormatBold as IconBold,
  MdFormatItalic as IconItalic,
  MdFormatUnderlined as IconUnderline,
  MdFormatPaint as IconMark,
  MdCode as IconCode,
  MdPhoto as IconImage,
  MdFullscreen as IconFull,
  MdLooksOne as IconHeadingOne,
  MdLooksTwo as IconHeadingTwo,
  MdLooks3 as IconHeadingThree,
  MdLooks4 as IconHeadingFour,
  MdInsertLink as IconLink,
  MdFormatQuote as IconQuote,
  MdFormatListNumbered as IconNumberedList,
  MdFormatListBulleted as IconBulletList
} from 'react-icons/md';

import { LongformEditor as TwitterEditor } from '../editor';

function MarkButton({ format, icon }) {
  const editor = useSlate();
  return <button className={TwitterEditor.isMarkActive(editor, format) ? 'active' : ''}
    onMouseDown={event => {
      event.preventDefault()
      TwitterEditor.toggleMark(editor, format);
    }}>{icon || format}</button>;
}

function BlockButton({ format, icon }) {
  const editor = useSlate();
  return <button className={TwitterEditor.isBlockActive(editor, format) ? 'active' : ''}
    onMouseDown={event => {
      event.preventDefault()
      TwitterEditor.toggleBlock(editor, format);
    }}>{icon || format}</button>;
}

function LinkButton({ icon }) {
  const editor = useSlate();
  return <button className={TwitterEditor.isBlockActive(editor, 'link') ? 'active' : ''}
    onMouseDown={event => {
      event.preventDefault()
      if (TwitterEditor.isBlockActive(editor, 'link')) {
        TwitterEditor.toggleBlock(editor, 'link');
      } else{
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        TwitterEditor.insertLink(editor, url);
      }
    }}>{icon || 'link'}</button>;
}

function ImageButton({ icon }) {
  const editor = useSlate();
  return <button
    onMouseDown={event => {
      event.preventDefault()
      const url = window.prompt('Enter the URL of the link:');
      if (!url) return;
      TwitterEditor.insertImage(editor, url);
    }}>{icon || 'image'}</button>;
}

function FullscreenButton({ active, toggle }) {
  return <button className={active ? 'active' : ''}
    onMouseDown={event => {
      event.preventDefault()
      toggle(event);
    }}><IconFull /></button>;
}

export default function Toolbar({ isFullScreen, toggleFullScreen }) {
  return <div className={`editor-toolbar${isFullScreen ? ' fixed' : ''}`}>
    <MarkButton format='bold' icon={<IconBold />} />
    <MarkButton format='italic' icon={<IconItalic />} />
    <MarkButton format='underline' icon={<IconUnderline />} />
    <MarkButton format='mark' icon={<IconMark />} />

    <BlockButton format='heading-one' icon={<IconHeadingOne />} />
    <BlockButton format='heading-two' icon={<IconHeadingTwo />} />
    <BlockButton format='heading-three' icon={<IconHeadingThree />} />
    <BlockButton format='heading-four' icon={<IconHeadingFour />} />

    <LinkButton icon={<IconLink />} />
    <ImageButton icon={<IconImage />} />
    <BlockButton format='bulleted-list' icon={<IconBulletList />} />
    <BlockButton format='numbered-list' icon={<IconNumberedList />} />
    <BlockButton format='block-quote' icon={<IconQuote />} />
    <BlockButton format='code' icon={<IconCode />} />
    <FullscreenButton active={isFullScreen} toggle={toggleFullScreen} />
  </div>
}