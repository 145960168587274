import React, { useCallback, useMemo, useState } from 'react';

import { createEditor, Range, Editor, Text } from 'slate';
import { withReact, useSlate } from 'slate-react';
import { withHistory } from 'slate-history';

import Toolbar from './Toolbar';

import {
  Longform,
  LongformEditor,
  serializeMarkdown,
  withDebug,
  withHtml,
  withImages,
  withBlogLayout,
  withLinks,
  withLists,
  withScrollspy
} from '../editor';

const initialComments = [
  {
    anchor: {
      path: [0, 0],
      offset: 0
    },
    focus: {
      path: [0, 0],
      offset: 1
    },
    highlight: true
  }
];

function Debug() {
  const editor = useSlate();
  return <div class='absolute right-0 top-0 w-96 text-xs'>
    <pre>{JSON.stringify(editor.children, null, 2)}</pre>
  </div>;
}

function Markdown() {
  const editor = useSlate();
  const markdown = serializeMarkdown(editor);
  return <div class='absolute left-0 top-0 w-96 text-xs'>
    <pre>{serializeMarkdown(editor)}</pre>
  </div>;
}

function LocalEditor({ debug, initialValue, onSave, readOnly, showToolbar = true }) {
  // Keep track of state for the value of the editor
  const [ value, setValue ] = useState(initialValue || [{
    type: 'heading-one',
    children: [{
      text: 'Untitled'
    }]
  }]);
  const [ comments, setComments ] = useState(initialComments);
  const [ isFullScreen, setFullScreen ] = useState(false);

  const editor = useMemo(() => withHtml(withImages(withReact(withHistory(withBlogLayout(withLists(withLinks(withScrollspy(createEditor())))))))), []);
  const toggleFullScreen = useCallback(() => setFullScreen(curr => !curr), []);
  const save = useCallback((newValue) => {
    setValue(newValue);
    onSave(newValue);
  }, []);

  const commentRefs = useMemo(() => {
    return comments.map(c => {
      const range = Editor.range(editor, c.anchor, c.focus);
      return Editor.rangeRef(editor, range);
    });
  }, [comments]);

  const decorate = useCallback(([node, path]) => {
    const ranges = [];
    if (!isFullScreen && Text.isText(node) && Range.includes(commentRefs[0].current, path)) {
      const intersection = Range.intersection(commentRefs[0].current, {
        anchor: {
          path: path,
          offset: 0
        },
        focus: {
          path: path,
          offset: node.text.length
        }
      });
      return [{...intersection, highlight: true}];
    }
    return ranges;
  }, [commentRefs, isFullScreen]);

  return <div className='longform-panel'>
    <Longform
      editor={editor}
      // decorate={decorate}
      keyHandlers={[]}
      onChange={save}
      readOnly={readOnly}
      value={value}>
      {showToolbar && !readOnly && <Toolbar isFullScreen={isFullScreen} toggleFullScreen={toggleFullScreen} />}
    </Longform>
  </div>;
}

export default LocalEditor;
