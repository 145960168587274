import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { diffChars } from 'diff';

import { useStore } from '../../store';

import { deserializeMarkdown, serializeMarkdown } from '../../editor';

// https://github.com/kpdecker/jsdiff
function MarkdownTest({ post }) {
  if (post.book_id) {
    const result = serializeMarkdown({children: deserializeMarkdown(post.content)}).trim();
    const diff = diffChars(post.content, result);
    return <div>
      {diff.map((d) => {
        const color = d.added ? 'green' : d.removed ? 'red' : 'gray';
        return <span className={`text-${color}-800 bg-${color}-100`}>
          {(d.added || d.removed) && d.value}
        </span>;
      })}
    </div>
  }
  return <span></span>;
}

function Posts() {
  const store = useStore();
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    fetch('/api/posts', {
      credentials: 'include',
      headers: {"Content-Type": "application/json"},
      method: 'GET'
    }).then(res => {
      res.json().then((posts) => {
        setDocs(d => [...d, ...posts]);
      });
    });
  }, []);

  return <ul>
    {docs.map((p, i) => <li key={i}>
      <Link to={`/view/${p.id}`}>{p.title}</Link> (<Link to={`/post/${p.id}`}>edit</Link>)
    </li>)}
  </ul>;
}

export default function Dashboard(props) {
  return <div className='max-w-screen-lg mx-auto'>
    <Link to='/write'>Write</Link>
    <Posts />
    <Link to="/tailwind">Tailwind</Link>
  </div>;
}
