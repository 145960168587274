import { Element, Text } from 'slate';
import { jsx } from 'slate-hyperscript'

import domHelpers from './dom';

const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'block-quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: el => ({ type: 'image', url: el.getAttribute('src'), title: el.getAttribute('alt') || '' }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
  HR: () => ({ type: 'hr'})
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  SMALL: () => ({ small: true })
}

export function deserializeHtml(content) {
  const doc = new DOMParser().parseFromString(content, 'text/html');
  return deserialize(doc.body);
}

// Taken from:
// https://developer.mozilla.org/en-US/docs/Web/API/Document_Object_Model/Whitespace
function stripWhitespace(node) {
  let text = node.textContent;

  // First, all spaces and tabs immediately before and after a line break are ignored so,
  // if we take our example markup from before and apply this first rule, we get:
  text = text.replace(/\s+\n/, '\n');
  text = text.replace(/\n\s+/, '\n');

  // Next, all tab characters are handled as space characters, so the example becomes:
  text = text.replace('\t', ' ');

  // Next, line breaks are converted to spaces:
  text = text.replace('\n', ' ');

  // After that, any space immediately following another space (even across two separate inline
  // elements) is ignored, so we end up with:
  text = text.replace(/\s+/, ' ');

  const before = domHelpers.nodeBefore(node);
  if (before && before.textContent.endsWith(' ')) {
    text = text.replace(/^\s+/, '');
  }

  const after = domHelpers.nodeAfter(node);
  if (!after) {
    text = text.replace(/\s+$/, '');
  }

  // And finally, sequences of spaces at the beginning and end of a line are removed, so we finally
  // get this:
  return text;
}

function deserialize(el) {
  if (el.nodeType === 3) {
    if (el.parentNode && (el.parentNode.nodeName === 'CODE' || el.parentNode.NodeName === 'PRE')) {
      return el.textContent;
    }
    return stripWhitespace(el)
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el
  let parent = el

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0]
  }

  let children = Array.from(parent.childNodes)
    .filter(n => !domHelpers.isIgnorable(n))
    .map(deserialize)
    .flat();

  if (children.length === 0) {
    children = [{text: ''}];
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map(child => {
      if (Element.isElement(child)) {
        applyDeeply(child, attrs);
        return child;
      } else {
        return jsx('text', attrs, child);
      }
    });
  }

  return children;
}

function applyDeeply(node, properties) {
  node.children.forEach((n) => {
    if (Text.isText(n)) {
      Object.keys(properties).forEach((p) => n[p] = properties[p]);
    } else {
      applyDeeply(n, properties);
    }
  });
}