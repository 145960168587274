import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Editor from '../../components/Editor';
import { useStore } from '../../store';
import { deserializeMarkdown, serializeMarkdown } from '../../editor';

import { HiBookOpen, HiLightningBolt, HiCloudUpload } from 'react-icons/hi';

function FocusMode({ children }) {
  return <div className='flex-grow'>
    {children}
  </div>;
}

function DebugMode({ children, value }) {
  const [markdown, setMarkdown] = useState(false);

  return <div className='flex min-w-0 overflow-hidden'>
    <div className='flex flex-grow min-w-0 w-1/2'>
      {children}
    </div>
    <div className='flex flex-grow min-w-0 w-1/2 overflow-hidden' onClick={(e) => setMarkdown(e => !e)}>
      <div className='text-xs min-w-0'>
        <pre>{markdown ? serializeMarkdown({children: value}) : JSON.stringify(value, null, 2)}</pre>
      </div>
    </div>
  </div>;
}

function EditorPanel({ isWriting, value, setValue }) {
  return <div className={`grid pb-64 mb-64${isWriting ? ' grid-hide' : ''}`}>
    <div className='mx-auto w-full max-w-screen-md'>
      <Editor initialValue={value} showToolbar={!isWriting} onSave={(newValue) => {
        setValue(newValue);
        // saveFn(store, id, newValue);
      }} />
    </div>
  </div>;
}

export default function Write(opts) {
  const [mode, setMode] = useState('focus');
  const { id } = useParams();
  const history = useHistory();
  const store = useStore();
  const [ value, setValue ] = useState();
  const [ isWriting, setIsWriting ] = useState(false);

  const handleKeyDown = useCallback(() => {
    setIsWriting(true);
  }, [])

  const handleMouseMove = useCallback(() => {
    setIsWriting(false);
  }, []);

  const save = useCallback(() => {
    const url = id ? `/api/write/${id}` : `/api/write`;
    const title = value[0].children[0].text;
    const content = serializeMarkdown({children: value.slice(1)});
    const options = {
      credentials: 'same-origin',
      headers: {"Content-Type": "application/json"},
      method: 'POST',
      body: JSON.stringify({ title, content })
    };
    fetch(url, options).then((res) => {
      if (res.status === 201) {
        return res.json().then((post) => {
          history.replace('/post/' + post.id);
        });
      }
      return res;
    });
  }, [id, value]);

  useEffect(() => {
    if (id) {
      const local = false && store.get(id);
      if (local) {
        setValue(local);
      } else {
        fetch(`/api/post/${id}`, {credentials: 'same-origin', method: 'GET'})
          .then((res) => {
            if (res.status === 200) {
              res.json().then((post) => {
                const doc = [
                  {type: 'heading-one', children: [{text: post.title}]},
                  ...deserializeMarkdown(post.content)
                ];
                setValue(doc);
              });
            } else {
              setValue([{
                type: 'paragraph',
                children:[{ text: 'A line of text in a paragraph' }]
              }])
            }
          });
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousemove', handleKeyDown);
    };
  }, [id, store, handleKeyDown, handleMouseMove]);

  if (!value && id) {
    return <div>loading..</div>;
  }

  const editor = <EditorPanel value={value} setValue={setValue} isWriting={isWriting} />;

  return <div className='flex'>
    <div className={`h-screen fixed bg-indigo-300 w-10${isWriting ? ' invisible': ''}`}>
      <button onClick={(e) => {e.preventDefault(); setMode('focus')}}><HiLightningBolt /></button>
      <button onClick={(e) => {e.preventDefault(); setMode('debug')}}><HiBookOpen /></button>
      <button onClick={(e) => save()}><HiCloudUpload /></button>
    </div>
    {mode === 'debug'
      ? <DebugMode isWriting={isWriting} value={value}>{editor}</DebugMode>
      : <FocusMode isWriting={isWriting}>{editor}</FocusMode>}
  </div>
}
